@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    @apply bg-gray-50;
}

body {
    @apply bg-gray-50;
    margin: 0;
    padding: 0;
    min-height: 100vh;
}

#root {
    @apply bg-gray-50;
    min-height: 100vh;
}
